import { useEffect, useState } from 'react';

const usePurchase = () => {
  const TAG = '[usePurchase]';
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    // PurchaseManager 할당
    PurchaseManager.attach(function (err: string | null, coin?: number, productId?: string) {
      if (err) {
        // PurchaseManager 내부에서 error popup을 띄우므로, 여기서는 아무것도 하지 않는다
        BLog.log(TAG, 'PurchaseManager error occurred:', err);
        return;
      }
    });

    // 보유한 코인 모니터링 및 업데이트
    const coinListener = MyDataManager.events.on('coinChanged', function (newCoin: number) {
      setCurrent(newCoin);
    });
    setCurrent(MyDataManager.getCoin());

    return () => {
      // 모든 리소스 해제
      PurchaseManager.detach();
      MyDataManager.events.removeListener('coinChanged', coinListener.token);
    };
  }, []);

  function buyCoin(productId: string) {
    PurchaseManager.buyCoin(productId);
  }

  return {
    current,
    buyCoin,
  };
};

export default usePurchase;
