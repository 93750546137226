import { ModalProps } from "pages/modal/Modal";
import { useEffect } from "react";
import { create } from "zustand";

interface ModalState {
    open: boolean;
    setOpen: (open: boolean) => void;
    props: ModalProps;
    setProps: (props: ModalProps) => void;
}

const useModalStore = create<ModalState>(set => ({
    open: false,
    setOpen: (open: boolean) => set(state => ({ open })),
    props: { content: '' },
    setProps: (props: ModalProps) => set(state => ({ props })),
}));

const useModal = () => {
    const { props, setProps } = useModalStore(state => state);

    useEffect(() => {
        const newProps: ModalProps = Transition.getModalProps();
        if (newProps.leftButton) {
            newProps.leftButton = { ...props.leftButton, ...newProps.leftButton, style: { ...props.leftButton?.style, ...newProps.leftButton!.style } };
        }
        if (newProps.rightButton) {
            newProps.rightButton = { ...props.rightButton, ...newProps.rightButton, style: { ...props.rightButton?.style, ...newProps.rightButton!.style } };
        }
        setProps({ ...newProps });
    }, [props, setProps]);

    return { props };
}

export default useModal;
