import { useEffect, useRef } from 'react';

interface CardContentProps {
  userInfo: UserInfo;
}

const CardContent: React.FC<CardContentProps> = ({ userInfo }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && !ref.current.querySelector('.cafe-card')) {
      const cardElem = TabCafe.buildCard(userInfo);
      cardElem.classList.add('current');
      ref.current.appendChild(cardElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={ref} style={{ width: '100%', height: '100%' }}></div>;
};

export default CardContent;
