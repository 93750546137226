import usePrevious from 'hooks/common/usePrevious';
import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';
import { create } from 'zustand';

interface BottomSheetProps {
  children: React.ReactNode;
  className?: string;
}

export interface BottomSheetRef {
  show: () => void;
  hide: () => void;
}

const BottomSheet = forwardRef<BottomSheetRef, BottomSheetProps>(({ children, className }, ref) => {
  const { open, show: _show, hide: _hide } = useBottomSheet({});
  const backgroundRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  function onClickBackground(e: any) {
    if (e.target.id === 'bottom-sheet-bg') {
      hide();
    }
  }

  function show() {
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.style.transform = 'translateY(0%)';
      }
    }, 0);
    if (backgroundRef.current) {
      backgroundRef.current.style.display = 'block';
    }
    _show();
  }

  function hide() {
    if (divRef.current) {
      divRef.current.style.transform = 'translateY(100%)';
    }
    setTimeout(() => {
      if (backgroundRef.current) {
        backgroundRef.current.style.display = 'none';
      }
      _hide();
    }, 300);
  }

  useImperativeHandle(ref, () => ({ show, hide }));

  return (
    <div
      ref={backgroundRef}
      onClick={onClickBackground}
      id="bottom-sheet-bg"
      className="w-full h-full absolute left-0 top-0 z-10"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'none' }}
    >
      <div
        ref={divRef}
        className={`flex flex-col absolute w-full bottom-0 bg-white p-4 rounded-t-xl ${className ?? ''}`}
        style={{ transition: 'all 0.3s', transform: 'translateY(100%)', paddingBottom: `${Device.name === 'iOS' ? MainUI.getNotchValue().navHeight + 40 : 40}px` }}
      >
        {open && children}
      </div>
    </div>
  );
});
export default BottomSheet;

interface BottomSheetState {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useBottomSheetStore = create<BottomSheetState>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
}));

interface UseBottomSheetProps {
  onClose?: () => void;
  swipe?: boolean;
}

export const useBottomSheet = ({ onClose, swipe = false }: UseBottomSheetProps) => {
  const [swiping, setSwiping] = useState(false);
  const [startY, setStartY] = useState<number | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const { open, setOpen } = useBottomSheetStore();
  const prevOpenState = usePrevious(open);

  const show = () => setOpen(true);

  const hide = () => setOpen(false);

  /* 스와이프 닫기 이벤트  */
  const handleBottomSheetScroll: React.EffectCallback = () => {
    const target = divRef.current;
    if (target === null) {
      return;
    }

    if (!swipe) {
      return;
    }

    target.addEventListener('touchstart', handleOverlayScrollStart);
    target.addEventListener('touchmove', handleOverlayScrollMove, { passive: false });
    target.addEventListener('touchend', handleOverlayScrollEnd);

    return () => {
      target.removeEventListener('touchstart', handleOverlayScrollStart);
      target.removeEventListener('touchmove', handleOverlayScrollMove);
      target.removeEventListener('touchend', handleOverlayScrollEnd);
    };

    function handleOverlayScrollStart(e: TouchEvent) {
      setStartY(e.touches[0].clientY);
      if (divRef.current!.scrollTop === 0) {
        divRef.current!.style.transition = 'unset';
      }
    }

    function handleOverlayScrollMove(e: TouchEvent) {
      if (startY === null) return;
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - startY;
      const scrollTop = divRef.current?.scrollTop;
      const scrollHeight = divRef.current!.scrollHeight;
      const offsetHeight = divRef.current!.offsetHeight;
      const contentHeight = scrollHeight - offsetHeight;

      if ((scrollTop === 0 && currentY > startY) || (scrollTop === contentHeight && currentY < startY)) {
        e.preventDefault();
      }
      if (divRef.current && scrollTop === 0 && deltaY > 0) {
        setSwiping(true);
        divRef.current!.style.overflowY = 'hidden';
        divRef.current.style.transform = `translateY(${Math.max(deltaY * 1.2, 0)}px)`;
      }
    }
    function handleOverlayScrollEnd(e: TouchEvent) {
      if (swiping) {
        setSwiping(false);
      }

      divRef.current!.style.transition = 'all 0.3s ease-in-out';
      const endY = e.changedTouches[0].clientY;
      const diff = endY - (startY ?? 0);
      if (diff < 250) {
        divRef.current!.style.transform = `translateY(0)`;
      } else {
        divRef.current!.style.transform = `translateY(100%)`;
        if (onClose) onClose();
      }

      setStartY(null);
      divRef.current!.style.overflowY = 'auto';
    }
  };

  useEffect(handleBottomSheetScroll, [divRef, startY, swiping, swipe, onClose]);
  return { open, prevOpenState, show, hide, divRef, swiping };
};
