import { AppBar, Body, PageLayout } from '../../components/common';
import CardSwipeSelection from '../../components/examples/CardSwipeSelection';

const Lovelink: React.FC = () => {
  return (
    <PageLayout>
      <AppBar title="러브링크 카드 예제" />
      <Body className="py-0 px-[20px]">
        <CardSwipeSelection />
      </Body>
    </PageLayout>
  );
};

export default Lovelink;
