import { PageLayout } from 'components/common';
import Button, { ButtonProps } from 'components/common/Button';
import useModal from 'hooks/common/useModal';

export interface ModalProps {
  title?: string;
  content: string;
  icon?: React.ReactNode;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
}

const Modal: React.FC = () => {
  const { props } = useModal();
  const { title, content, icon, leftButton, rightButton } = props;
  const defaultLeftButtonProps: ButtonProps = {
    style: { text: brsc.cancel, color: 'gray-400', fontColor: 'text-700' },
    onClick: () => {},
  };
  const defaultRightButtonProps: ButtonProps = {
    style: { text: brsc.confirm },
    onClick: () => {},
  };
  return (
    <PageLayout overlay>
      <div className="flex items-center flex-auto mx-4 my-auto bg-white px-4 pt-8 pb-5 rounded-lg flex-column text-center shadow-md">
        {icon ? icon : <></>}
        {title && <div className="text-title2 text-text-700 pt-4">{title}</div>}
        <div className="text-3 text-black pt-3 pb-6 text-text-300">{content}</div>
        <div className="flex full-width gap-2">
          <Button style={{ ...defaultLeftButtonProps.style, ...leftButton?.style }} onClick={leftButton?.onClick ?? (() => Transition.back())} />
          <Button style={{ ...defaultRightButtonProps.style, ...rightButton?.style }} onClick={rightButton?.onClick ?? (() => {})} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Modal;
