import { AppBar, Body, PageLayout } from 'components/common';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

const CloverSwipeScreen: React.FC = () => {
  // const rtl = BResource.getSelectedLocale() === 'ar';

  const listItem = (text: string) => {
    return (
      <div className="flex flex-row items-center gap-2">
        <div className="w-2 h-2 rounded-full bg-gray-700" />
        {text}
      </div>
    );
  };

  const backNSwipe = () => {
    Transition.resetToHome();
    setTimeout(() => {
      FindUI.swipe();
    }, 300);
  };

  return (
    <PageLayout backgroundColor="bg-black">
      <AppBar
        className="text-white bg-black text-title4"
        title={`✨You have been chosen as today's lucky star!✨`}
        trailings={<Icon name="cafe/close.svg" className="m-3" onClick={() => Transition.back()} />}
      />
      <Body
        onClose={() => Transition.back()}
        className="flex flex-col items-center"
        backgroundColor="#000"
        style={{
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div className="w-[70%] mt-6">
          <img className="object-cover w-full" src="/imgweb/find_her/clover.png" alt="" />
        </div>
        <div className="flex flex-col w-full px-5 gap-4">
          {listItem('You can get only 10 in a day!')}
          {listItem('Get clovers and give a special gift!')}
          {listItem('Start match, and have a special day!')}
        </div>
        <div className="flex grow"></div>
        <div className="text-4 text-gray-700 mb-4">{' This event can be closed suddenly'}</div>
        <div className="w-full px-4">
          <Button className="w-full" style={{ text: brsc.btn_region_popup, type: 'solid' }} onClick={backNSwipe} />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'gray-700' }} onClick={() => Transition.back()} />
        </div>
        <div className="flex grow"></div>
      </Body>
    </PageLayout>
  );
};

export default CloverSwipeScreen;
