import { AppBar, Body, PageLayout } from 'components/common';
import SettingList from 'components/SettingList';

const PageAbout: React.FC = () => {
  const navHeight = Device.name === 'iOS' ? MainUI.getNotchValue().navHeight + 30 : 30;

  const handleLinkClick = (title: string, url: string): void => {
    Transition.showPage(Def.PAGE_IFRAME, { title, url });
  };

  const handleContactUsClick = () => {
    const recipient = 'appbermuda@gmail.com';
    const subject = '[Inquiry] ';
    const body = '*Please provide the following details:\n';
    const bodySignature = `- Inquiry: \n- Device model: \n- Account: ${myinfo.fbUid}\n`;

    const format = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body + bodySignature)}`;
    const encodedUrl = format.replaceAll(' ', '%20').replaceAll('\n', '%0D%0A');

    Device.openURL(encodedUrl);
  };

  const locale = BResource.getSelectedLocale();
  const ppTitle = locale === 'ko' ? '개인정보 처리방침' : 'Privacy Policy';
  const ppHtml = locale === 'ko' ? 'privacypolicy-ko.html' : 'privacypolicy.html';

  const isCookieHide = !ConsentManager.checkVisibleInAboutPage();

  const getServerVersion = (): string => {
    const version = BResource.getVersion();
    const host = window.location.host;
    let server = '';

    if (host === 'staging.thebermuda.net') {
      server = '/stg';
    } else if (host === 'dev.gobermuda.net' || host === 'dev.thebermuda.net') {
      server = '/dev';
    } else if (host.startsWith('bgrok.thebermuda.net')) {
      server = '/local';
    }
    return version + '/' + WEB_VERSION + server;
  };

  return (
    <PageLayout>
      <AppBar title={brsc.about_title} />
      <Body className="pt-[20px] pb-[30px]">
        <div className="flex flex-col items-center px-5">
          <img className="w-[4.5rem] h-[4.5rem] p-1" alt="logo" src="/imgweb/main/logo_flat.svg" />
          <div className="my-6 text-sm"> {brsc.about_detail} </div>
        </div>
        <SettingList text="Contact" icon="email" onClick={handleContactUsClick} />
        <SettingList text="Open Source Licenses" icon="page" onClick={() => handleLinkClick('Open Source Licenses', '/credits.html')} />
        <SettingList text="Terms of Service" icon="page" onClick={() => handleLinkClick('Terms of Service', 'https://www.thebermuda.net/termsofservice.html')} />
        <SettingList text="Community Rules" icon="page" onClick={() => handleLinkClick('Community Rules', 'https://www.thebermuda.net/community.html')} />
        <SettingList text={ppTitle} icon="page" onClick={() => handleLinkClick(ppTitle, `https://www.thebermuda.net/${ppHtml}`)} />
        <SettingList text="Cookies" onClick={() => ConsentManager.showBanner()} hide={isCookieHide} />
        <div style={{ bottom: navHeight + 'px' }} className="absolute left-1/2 transform -translate-x-1/2">
          <div className="bg-gray-200 text-center w-auto py-2 px-4 rounded-full text-5">{getServerVersion()}</div>
        </div>
      </Body>
    </PageLayout>
  );
};

export default PageAbout;
