import { useEffect, useState } from 'react';
import { AppBar, Body, PageLayout } from '../../components/common';

const PageRoute: React.FC = () => {
  const [uid, setUid] = useState<string>();

  useEffect(() => {
    if (BERMUDA_ENV === 'development3') {
      setUid('JFONxCIg5qSfqm3nPg8Q96qAlo03');
    } else {
      setUid('2M721MJlRbTZKCwn7jljQWrRbUt2');
    }
  }, []);

  function buildPageRouteButton(label: string, page: string, data?: any) {
    return (
      <div style={{ padding: '4px 0px' }}>
        <span>{`${label} :`}&nbsp;&nbsp;</span>
        <button
          style={{ padding: '0px 16px' }}
          onClick={() => {
            Transition.showPage(page, data);
          }}
        >
          이동하기
        </button>
      </div>
    );
  }

  function buildTabRouteButton(label: string, tab: string) {
    return (
      <div style={{ padding: '4px 0px' }}>
        <span>{`${label} :`}&nbsp;&nbsp;</span>
        <button
          style={{ padding: '0px 16px' }}
          onClick={() => {
            Transition.moveTab(tab);
          }}
        >
          이동하기
        </button>
      </div>
    );
  }

  return (
    <PageLayout>
      <AppBar title="라우팅 예제" />
      <Body className="py-0 px-[20px]">
        <h3>페이지 이동</h3>
        {buildPageRouteButton('코인샵', Def.PAGE_SHOP)}
        {buildPageRouteButton('프로필', Def.PAGE_PROFILE, { uid: uid })}
        {buildPageRouteButton('채팅룸', Def.PAGE_CHATROOM, { fbUid: uid })}

        <h3>탭 이동</h3>
        {buildTabRouteButton('홈', Def.TAB_HOME)}
        {buildTabRouteButton('씬', Def.TAB_SCENE)}
        {buildTabRouteButton('카페', Def.TAB_CAFE)}
        {buildTabRouteButton('채팅', Def.TAB_CHAT)}
      </Body>
    </PageLayout>
  );
};

export default PageRoute;
