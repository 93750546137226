interface IconProps {
  name: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  size?: number;
}
const Icon: React.FC<IconProps> = ({ name, className, onClick, style, size = 32 }) => {
  return (
    <div className={`flex align-center justify-center ${className}`} onClick={onClick} style={{ ...style, width: `${size}px`, height: `${size}px` }}>
      <img className="" src={`/imgweb/${name}`} alt="" style={{ width: `${size / 2}px`, height: `${size / 2}px` }} />
    </div>
  );
};

export default Icon;
