import { PageLayout } from 'components/common';
import Button from 'components/common/Button';
import { useEffect, useState } from 'react';

const TimeBuffPurchaseComplete: React.FC = () => {
  const [fadeIn, setFadeIn] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 300);
  }, []);

  const handleUseClick = () => {
    closeAll();
    setTimeout(() => {
      Transition.showPageWithoutHide(Def.BOTTOM_SHEET_TIME_BUFF_FILTER);
    }, 500);
  };

  const handleLaterClick = () => closeAll();

  const closeAll = () => {
    Transition.resetToHome();
  };

  return (
    <PageLayout overlay opacity={0.85}>
      <div className="w-full h-full flex flex-column justify-between">
        <div></div>
        <div className="w-full flex flex-column align-center justify-center px-5">
          <div className="relative overflow-hidden flex">
            <img className="w-full px-7 transition-opacity duration-500" style={{ opacity: fadeIn ? 1 : 0 }} src="/imgweb/sale/infinity_silver.png" alt="" />
            <GliterIcon className="absolute bottom-0 left-4" />
            <GliterIcon className="absolute top-0 right-4" />
          </div>
          <div className="text-white text-title1 mt-3">{brsc.prchase_complete_title}</div>
        </div>
        <div className="w-full flex flex-column gap-4 px-5 pb-5">
          <Button className="w-full" style={{ text: brsc.prchase_complete_btn, color: 'white', fontColor: 'text-700' }} onClick={handleUseClick} />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'white' }} onClick={handleLaterClick} />
        </div>
      </div>
    </PageLayout>
  );
};

export default TimeBuffPurchaseComplete;

interface GliterIconProps {
  className?: string;
}

const GliterIcon: React.FC<GliterIconProps> = ({ className }) => {
  return (
    <div className={`${className ?? ''} w-12 h-12`}>
      <svg width="100" height="100" viewBox="0 0 100 100" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <g className="group" opacity="0.8">
          <g className="large">
            <path
              d="M41.25,40 L42.5,10 L43.75,40 L45, 41.25 L75,42.5 L45,43.75
                      L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
              fill="white"
            />
          </g>
          <g className="large-2" transform="rotate(45)">
            <path
              d="M41.25,40 L42.5,10 L43.75,40 L45, 41.25 L75,42.5 L45,43.75
                      L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
              fill="white"
            />
          </g>
          <g className="small">
            <path
              d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75
                      L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
              fill="white"
            />
          </g>
        </g>
      </svg>
      <style>{`
        .group {
          transform: translate(42.5px, 42.5px);
        }
        path {
          transform: translate(-42.50px,-42.50px);
        }
        .large {
          opacity: 0;
          animation: large 8s linear infinite;
          -webkit-animation: large 8s linear infinite;
        }
        .large-2 {
          opacity: 0;
          animation: large-2 8s linear infinite;
        }
        .small {
          opacity: 0;
          animation: small 8s linear infinite;
        }
        @keyframes large {
          0% {
            opacity: 0;
            transform: rotate(0deg) scale(0);
            -webkit-transform: rotate(0deg) scale(0);
          }
          16.667% {
            opacity: 1;
          }
          33.333% {
            opacity: 0;
            transform: rotate(360deg) scale(1.5);
             -webkit-transform: rotate(360deg) scale(1.5);
          }
          100% {
            opacity: 0;
            transform: rotate(0deg) scale(0);
            -webkit-transform: rotate(0deg) scale(0);
          }
        }
        @keyframes large-2 {
          0% {
            opacity: 0;
            transform: rotate(45deg) scale(0);
          }
          16.667% {
            opacity: 1;
          }
          33.333% {
            opacity: 0;
            transform: rotate(405deg) scale(1.1);
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) scale(0);
          }
        }
        @keyframes small {
          0% {
            opacity: 0;
            transform: rotate(0deg) scale(0);
          }
          16.667% {
            opacity: 1;
          }
          33.333% {
            opacity: 0;
            transform: rotate(-360deg) scale(1.5);
          }
          100% {
            opacity: 0;
            transform: rotate(0deg) scale(0);
          }
        }
      `}</style>
    </div>
  );
};