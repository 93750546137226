import { useCallback, useEffect, useRef } from 'react';

interface CardSliderProps<T> {
  items: T[];
  style?: React.CSSProperties;
  className?: string;
  currentIndex: number;
  buildItems: (item: T, index: number) => React.ReactNode;
  onSelect: (index: number) => void;
}

const CardSlider = <T,>({ items, buildItems, currentIndex, onSelect, style, className }: CardSliderProps<T>): React.ReactElement => {
  const divRef = useRef<HTMLDivElement>(null);

  const scrollToItem = useCallback(
    (index: number) => {
      const parent = divRef.current;
      if (!parent) return;

      const children = Array.from(parent.children) as HTMLElement[];

      if (index < 0 || index >= children.length) return;
      const targetChild = children[index];
      const offset = targetChild.offsetLeft - parent.clientWidth / 2 + targetChild.clientWidth / 2;

      parent.scrollTo({
        left: offset,
        behavior: 'smooth',
      });

      onSelect(index);
    },
    [onSelect]
  );

  useEffect(() => {
    let isScrolling = false;
    let startX = 0;

    const handleStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      isScrolling = true;
    };

    const handleEnd = (e: TouchEvent) => {
      if (isScrolling) {
        const position = e.changedTouches[0].clientX;
        let diff = position - startX;
        if (BResource.getSelectedLocale() === 'ar') {
          diff = -diff;
        }

        if (diff < -40) {
          if (currentIndex === items.length - 1) return;
          scrollToItem(currentIndex + 1);
        } else if (diff > 40) {
          if (currentIndex === 0) return;
          scrollToItem(currentIndex - 1);
        }
      }
      isScrolling = false;
    };

    const parent = divRef.current;

    parent?.addEventListener('touchstart', handleStart);
    parent?.addEventListener('touchend', handleEnd);

    return () => {
      parent?.removeEventListener('touchstart', handleStart);
      parent?.removeEventListener('touchend', handleEnd);
    };
  }, [currentIndex, items.length, scrollToItem]);

  return (
    <div
      ref={divRef}
      className={`flex flex-row w-full overflow-x-scroll no-scrollbar overflow-y-hidden ${className ?? ''} gap-6`}
      style={{ ...style, overscrollBehavior: 'none', whiteSpace: 'nowrap' }}
    >
      {items.map((item, index) => buildItems(item, index))}
    </div>
  );
};

export default CardSlider;
