import { AppBar, Body, Button, Icon, PageLayout } from 'components/common';
import { useState } from 'react';

const SettingLuckyStarScreen: React.FC = () => {
  const [btnState, setBtnState] = useState<boolean>(false);

  const checkPermission = () => {
    if (Device.name === 'iOS') {
      return iOSUtil.getPermission('notification');
    } else {
      return Device.getNotificationByKey('event') === 'on';
    }
  };

  const handleClickButton = async () => {
    if (!checkPermission()) {
      const result = await CommonUI.showCommonDialog(
        `<div class="flex-column gap-16"><div class="text-title2">${brsc.push_noti_popup_1}</div><div class="text4">${brsc.push_noti_popup_2}</div></div>`,
        'noti-setting',
        { btnName: brsc.agree_btn },
        { btnName: brsc.disagree_btn }
      );
      if (result) {
        Device.goToPermissionSetting();
      }
    } else {
      setBtnState(true);
    }
  };

  const numberListItem = (number: number, text: string) => {
    return (
      <div className="flex flex-row items-center gap-2">
        <div className="rounded-full w-6 h-6 bg-gray-700 shrink-0 text-center text-title4">{number}</div>
        {text}
      </div>
    );
  };

  const divider = () => {
    return (
      <div className="h-4 w-6 flex items-center justify-center">
        <div className="w-[1.5px] h-4 rounded bg-white" />
      </div>
    );
  };

  const listItem = (text: string) => {
    return (
      <div className="flex flex-row items-center gap-2 text-gray-700 text4">
        <div className="w-1 h-1 rounded-full bg-gray-700 shrink-0" />
        {text}
      </div>
    );
  };

  return (
    <PageLayout backgroundColor="bg-black">
      <AppBar
        className="text-white bg-black text-title4"
        title={`💝Find the Lucky Star💝`}
        trailings={<Icon name="cafe/close.svg" className="m-3" onClick={() => Transition.back()} />}
      />
      <Body
        onClose={() => Transition.back()}
        className="flex flex-col items-center"
        backgroundColor="#000"
        style={{
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
          overflowX: 'hidden',
        }}
      >
        <div className="flex flex-col h-[320px] min-h-[320px] w-full bg-black gap-2">
          <img className="absolute left-0 top-[0px] flex h-[160px] w-auto" src="/imgweb/find_her/slide01.jpg" alt="" />
          <img className="absolute right-0 top-[160px] flex h-[160px] w-auto" src="/imgweb/find_her/slide02.jpg" alt="" />
        </div>
        <div className="flex flex-col w-full p-6 gap-1">
          {numberListItem(1, brsc.m_detail_1)}
          {divider()}
          {numberListItem(2, brsc.m_detail_2)}
          {divider()}
          {numberListItem(3, brsc.m_detail_3)}
        </div>
        <div className="flex flex-col w-full p-6 gap-4">
          {listItem(brsc.m_detail_4)}
          {listItem(brsc.m_detail_5)}
          {listItem(brsc.m_detail_6)}
          {listItem(brsc.m_detail_7)}
        </div>
        <div className="w-full px-4">
          <Button
            className="w-full"
            style={{
              text: btnState ? brsc.my_charm_score_promo_btn : brsc.m_push_on_btn,
              type: 'solid',
              color: btnState ? 'primary-200' : 'primary-700',
              fontColor: btnState ? 'primary-700' : 'white',
            }}
            onClick={handleClickButton}
          />
        </div>
        <div className="flex min-h-[100px]" />
      </Body>
    </PageLayout>
  );
};

export default SettingLuckyStarScreen;
