import { AppBar, Body, PageLayout } from '../../components/common';

const Dialog: React.FC = () => {
  function buildDialogButton(label: string, onClick: () => void) {
    return (
      <div style={{ padding: '4px 0px' }}>
        <span>{`${label} :`}&nbsp;&nbsp;</span>
        <button style={{ padding: '0px 16px' }} onClick={onClick}>
          보기
        </button>
      </div>
    );
  }

  return (
    <PageLayout>
      <AppBar title="다이어로그 예제" />
      <Body className="py-0 px-[20px]">
        {buildDialogButton('Dialog', () => CommonUI.showDialogV2('dialog', null, () => {}))}
        {buildDialogButton('Bottom sheet', () => CommonUI.showBottomSheetDialog('bottom sheet dialog', null, () => {}))}
        {buildDialogButton('Toast', () => CommonUI.showMessage('log', '안녕하세요'))}
      </Body>
    </PageLayout>
  );
};

export default Dialog;
