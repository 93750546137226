import React from 'react';

interface SwitchProps {
  isOn: boolean;
  onChange?: (isOn: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ isOn, onChange }) => {
  const trackColor = isOn ? 'bg-primary-700' : 'bg-gray-200';
  const thumbOffset = !isOn ? 'translate-x-[6px]' : 'translate-x-[30px]';
  const thumbSize = isOn ? 'w-[20px] h-[20px]' : 'w-[12px] h-[12px]';
  const onClick = onChange ? () => onChange(!isOn) : () => {};

  return (
    <div className={`flex flex-row w-[54px] h-[28px] rounded-full ${trackColor} items-center transition-all`} onClick={onClick}>
      <div className={`${thumbOffset} ${thumbSize} rounded-full bg-white transition-all`} />
    </div>
  );
};

export default Switch;
