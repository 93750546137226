import { AppBar, Body, PageLayout } from '../../components/common';
import usePurchase from '../../hooks/examples/usePurchase';

const Purchase: React.FC = () => {
  const { current, buyCoin } = usePurchase();

  function buildPurchaseButton(productId: string) {
    return (
      <div style={{ padding: '4px 0px' }}>
        <span>{`${productId.split('_')[1]}코인 :`}&nbsp;&nbsp;</span>
        <button
          style={{ padding: '0px 16px' }}
          onClick={() => {
            buyCoin(productId);
          }}
        >
          구매하기
        </button>
      </div>
    );
  }

  return (
    <PageLayout>
      <AppBar title="결제 예제" />
      <Body className="py-0 px-[20px]">
        <div style={{ padding: '4px 0px' }}>
          <span>{`보유한 코인 : ${current}`}</span>
        </div>
        {buildPurchaseButton('ccoin_500')}
        {buildPurchaseButton('ccoin_1200')}
        {buildPurchaseButton('ccoin_2500')}
      </Body>
    </PageLayout>
  );
};

export default Purchase;
