import SettingIcon from "./SettingIcon";

interface SettingListProps {
  text: string;
  onClick: () => void;
  icon?: string | React.ReactNode;
  hide?: boolean;
}

const SettingList: React.FC<SettingListProps> = ({ text, onClick, icon, hide }) => {
  return (
    <div className={`setting-list py-4 px-5 text-2 flex items-center justify-between ${hide ? 'hidden' : ''}`} onClick={onClick}>
      <div>{text}</div>
      {typeof icon === 'string' && <SettingIcon icon={icon} />}
      {typeof icon === 'object' && icon}
    </div>
  );
};

export default SettingList;
