import { useBottomSheet } from './BottomSheet';
interface BodyProps {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  overlay?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
}

const Body: React.FC<BodyProps> = ({ children, className = '', backgroundColor, style, overlay = false, onClose, fullScreen = false }) => {
  const { divRef, swiping } = useBottomSheet({ onClose, swipe: overlay });
  const fullScreenStyle = fullScreen ? { top: 0, paddingTop: MainUI.getNotchValue().appbarTop, height: '100%' } : {};

  return (
    <div
      ref={overlay ? divRef : null}
      className={`block absolute w-full h-[calc(100%-56px)] left-0 top-[56px] p-0 z-[5] overflow-y-auto ${!backgroundColor ? 'bg-white' : ''} text-black ${className}`}
      style={{ ...style, backgroundColor, touchAction: overlay && swiping ? 'none' : 'unset', overscrollBehavior: 'none', ...fullScreenStyle }}
    >
      {children}
    </div>
  );
};

export default Body;
