import { AppBar, Body, PageLayout } from '../../components/common';

const Examples: React.FC = () => {
  function buildItem(label: string, path: string) {
    return (
      <div className="mb-2">
        <span>{`${label} :`}&nbsp;&nbsp;</span>
        <button
          className="px-2"
          onClick={() => {
            Transition.showPage(path);
          }}
        >
          보기
        </button>
      </div>
    );
  }

  return (
    <PageLayout>
      <AppBar title="리액트 예제" />
      <Body className="py-0 px-[20px]">
        {buildItem('라우팅', '/examples/page-route')}
        {buildItem('스크롤 복원', '/examples/scroll-restoration')}
        {buildItem('다이어로그', '/examples/dialog')}
        {buildItem('결제', '/examples/purchase')}
        {buildItem('러브링크', '/examples/lovelink')}
        {buildItem('버튼', '/examples/buttons')}
      </Body>
    </PageLayout>
  );
};

export default Examples;
