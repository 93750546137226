import { useEffect } from 'react';
import { CardId, CardSwiper } from 'react-card-swiper';
import useLovelink from '../../hooks/examples/useLovelink';
import CardContent from './CardContent';

const imgStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export default function CardSwipeSelection() {
  const { userInfos, bgInfo, fetchNext } = useLovelink();

  // Data fetch 중 로딩 표시
  useEffect(() => {
    if (bgInfo) {
      BUtil.showLoading('dataLoading', 20000, '#BBDEFB', true);
    } else {
      BUtil.hideLoading('dataLoading');
    }

    return () => {
      BUtil.hideLoading('dataLoading');
    };
  }, [bgInfo]);

  // 카드가 1장 남았을 때 다음 데이터를 가져옴
  const handleEnter = (element: HTMLDivElement, meta: any, id: CardId) => {
    if (meta.isLast) {
      setTimeout(() => {
        fetchNext();
      }, 500);
    }
  };

  return (
    <>
      {userInfos.length > 0 ? (
        <CardSwiper
          data={userInfos.map((userInfo, index) => ({
            id: userInfo.fbUid,
            meta: { isLast: index === 0 },
            src: '',
            content: <CardContent userInfo={userInfo} />,
          }))}
          onEnter={handleEnter}
          onFinish={() => fetchNext()}
          withRibbons
          likeRibbonText="좋아요"
          dislikeRibbonText="싫어요"
          ribbonColors={{
            bgLike: 'green',
            bgDislike: 'red',
            textColor: 'white',
          }}
        />
      ) : (
        <>
          {bgInfo ? (
            <CardContent userInfo={bgInfo} />
          ) : (
            <img
              style={imgStyle}
              src="https://firebasestorage.googleapis.com/v0/b/bermuda-e96c4.appspot.com/o/users%2F2M721MJlRbTZKCwn7jljQWrRbUt2%2Fmain.jpg?alt=media&token=88e798ca-b8f4-47d8-8a87-bb9ae4922129"
              alt="loading"
            />
          )}
        </>
      )}
    </>
  );
}
