import { useEffect, useState } from 'react';

interface UserList {
  list: string[];
}

const useLovelink = () => {
  const [userInfos, setUserInfos] = useState<UserInfo[]>([]);
  const [bgInfo, setBgInfo] = useState<UserInfo | null>();

  useEffect(() => {
    fetchNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchNext() {
    const lastElem = userInfos.length > 0 ? userInfos[0] : null;

    setBgInfo(lastElem);
    setUserInfos([]);

    CafeDataManager.getUserList(function (err: string | null, result?: UserList) {
      if (err) {
        return;
      }

      const uidList = result!.list.map(function (uid) {
        return { uid: uid };
      });

      FriendsDataManager.getInfos(uidList, 0, uidList.length, function (no: UserInfo[]) {
        const infos = no.filter((info) => info?.nick);

        if (lastElem) {
          infos.splice(0, 0, lastElem);
        }
        setUserInfos(infos.reverse());
        setBgInfo(null);
      });
    });
  }

  return { userInfos, bgInfo, fetchNext };
};

export default useLovelink;
