import { AppBar, Body, PageLayout } from '../../components/common';

const ScrollRestoration: React.FC = () => {
  return (
    <PageLayout>
      <AppBar title="스크롤 복원 예제" />
      <Body className="py-0 px-[20px]">
        <div className={`w-full h-[150vh] bg-gradient-to-b from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ...`}></div>
        <button className={'w-full h-[48px] my-[20px]'} onClick={() => Transition.showPage(Def.PAGE_SHOP)}>
          코인샵 페이지로 이동
        </button>
      </Body>
    </PageLayout>
  );
};

export default ScrollRestoration;
