import { AppBar, Body, PageLayout } from 'components/common';
import Button from 'components/common/Button';
import React from 'react';

const ButtonExamples: React.FC = () => {
  return (
    <PageLayout>
      <AppBar />
      <Body className="p-[16px] gap-[8px] flex flex-col">
        <Button style={{ text: 'Primary 700 Solid Button', color: 'primary-700' }} />
        <Button style={{ text: 'primary 400 Solid Button', color: 'primary-400' }} />
        <Button style={{ text: 'Secondary 300 Solid Button', color: 'secondary-700' }} />
        <Button style={{ text: 'Warning Solid 700 Button', color: 'warning-700' }} />
        <Button style={{ text: 'Primary 700 Outline Button', type: 'outline', color: 'primary-700' }} />
        <Button style={{ text: 'Warning 300 Outline Button', color: 'warning-300', type: 'outline', fontTheme: 'title1' }} />
        <div className="flex flex-row gap-2">
          <Button style={{ text: 'Button', color: 'warning-300', type: 'outline', fontTheme: 'title1' }} onClick={() => {}} />
          <Button style={{ text: 'Button', color: 'warning-300', type: 'outline', fontTheme: '1' }} />
        </div>
      </Body>
    </PageLayout>
  );
};

export default ButtonExamples;
