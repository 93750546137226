import { AppBar, Body, Button, Icon, PageLayout } from 'components/common';

const FindLuckyStarScreen: React.FC = () => {
  const backNSwipe = () => {
    Transition.resetToHome();
    setTimeout(() => {
      FindUI.swipe();
    }, 300);
  };
  return (
    <PageLayout backgroundColor="bg-black">
      <AppBar className="text-white bg-black text-title4" trailings={<Icon name="cafe/close.svg" className="m-3" onClick={() => Transition.back()} />} />
      <Body
        onClose={() => Transition.back()}
        className="flex flex-col items-center"
        backgroundColor="#000"
        style={{
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        <div className="flex flex-col h-[320px] min-h-[320px] w-full bg-black gap-2">
          <img className="absolute left-0 top-[0px] flex h-[160px] w-auto" src="/imgweb/find_her/slide01.jpg" alt="" />
          <img className="absolute right-0 top-[160px] flex h-[160px] w-auto" src="/imgweb/find_her/slide02.jpg" alt="" />
        </div>
        <div className="flex grow" />
        <div className="flex flex-col items-center px-4">
          <div className="text-title2">💝Find the Lucky Star💝</div>
          <div className="text2 text-center">{brsc.m_detail_3}</div>
        </div>
        <div className="h-6" />
        <div className="w-full px-4">
          <Button
            className="w-full"
            style={{
              text: brsc.btn_region_popup,
              type: 'solid',
              background: 'linear-gradient(116deg, #FFADCF 1.5%, #FF4390 50.75%, #FF4390 100%)',
            }}
            onClick={backNSwipe}
          />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'gray-700' }} onClick={() => Transition.back()} />
        </div>
        <div className="flex grow" />
        <div className="flex min-h-[50px]" />
      </Body>
    </PageLayout>
  );
};

export default FindLuckyStarScreen;
