interface AppBarProps {
  title?: string;
  trailings?: React.ReactNode;
  className?: string;
}

const AppBar: React.FC<AppBarProps> = ({ title, trailings, className }) => {
  return (
    <div className={`appbar ${className ?? ''}`}>
      <div className="appbar-exit" onClick={() => Transition.back()}>
        <span className="icon-arrow_back"></span>
      </div>
      <div className="appbar-content">
        <div className="appbar-title pt-0">{title || ''}</div>
      </div>
      {!!trailings && <div className="absolute right-0 top-0">{trailings}</div>}
    </div>
  );
};

export default AppBar;
