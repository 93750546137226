import { useEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

const rootElem = document.getElementById('root');

const useScrollRestoration = () => {
  const TAG = '[useScrollRestoration]';

  const location = useLocation();
  const navigationType = useNavigationType();

  /**
   * 뒤로 가기에 인한 페이지 전환 시, sessionStorage에 저장된 React page의 body scroll 위치를 복원.
   */
  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      const bodyElem = rootElem?.querySelector('.ctab');
      const savedPosition = sessionStorage.getItem(location.pathname);
      if (bodyElem && savedPosition) {
        const scrollOffset = parseInt(savedPosition, 10);
        bodyElem.scrollTo(0, scrollOffset);
        BLog.log(TAG, 'scroll position restored:', location.pathname, scrollOffset);
      }
    }
  }, [location, navigationType]);

  /**
   * React page의 body scroll 위치를 sessionStorage에 저장. 저장된 scroll 위치는 페이지가 pop 될 때 hook 내부에서 자동으로 복원한다.
   */
  function store(path: string) {
    const bodyElem = rootElem?.querySelector('.ctab');
    if (bodyElem) {
      const scrollOffset = bodyElem.scrollTop.toString();
      sessionStorage.setItem(path, scrollOffset);
      BLog.log(TAG, 'scroll position stored:', path, scrollOffset);
    }
  }

  return { store };
};

export default useScrollRestoration;
