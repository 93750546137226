interface SettingIconProps {
  icon: string;
}

const SettingIcon: React.FC<SettingIconProps> = ({ icon }) => {
  const renderIcon = () => {
    if (icon === 'page') {
      return <span className="icon-chevron-right"></span>;
    } else if (icon === 'email') {
      return (
        <>
          <img className="w-4 h-4 mx-1" alt="email-icon" src="/imgweb/etc/mail_icon.svg" />
          appbermuda@gmail.com
        </>
      );
    }
  };

  return <div className="flex items-center">{renderIcon()}</div>;
};

export default SettingIcon;
