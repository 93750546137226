import React, { useState } from 'react';
import { useEffect } from 'react';
import { AppBar, Body, PageLayout, Switch } from 'components/common';
import SettingList from 'components/SettingList';

const PagePrivacySetting: React.FC = () => {
  const primeAvailable = PrimeCommon.isPrimeActivate() === true && PrimeDataManager.getState() === 0;

  const [hideCity, setHideCity] = useState<boolean>(myinfo.hidecity === 'y');
  const [screenLock, setScreenLock] = useState<boolean>(myinfo.screenlock === 'passcode');
  const [primeMatch, setPrimeMatch] = useState<boolean>(myinfo.primeMatch === 'on');

  useEffect(() => {
    refreshScreenLock();
  }, []);

  function refreshScreenLock() {
    ScreenLockManager.getPasscode(function (code: any) {
      myinfo.screenlock = code ? 'passcode' : 'off';
      setScreenLock(!!code);
    });
  }

  function onChangeHideCity(isOn: boolean) {
    setHideCity(isOn);
    myinfo.hidecity = isOn ? 'y' : 'n';
    MyDataManager.save('hidecity');
  }

  function onChangeScreenLock(isOn: boolean) {
    if (!isOn) {
      myinfo.screenlock = 'off';
      ScreenLockManager.removePasscode();
      setScreenLock(isOn);
    } else {
      ScreenLockManager.showAsync('register').then(() => refreshScreenLock());
    }
  }

  function onChangePrimeMatch(isOn: boolean) {
    myinfo.primeMatch = isOn ? 'on' : 'off';
    MyDataManager.save('primeMatch');
    PrimeCommon.updatePrimeMatchBtnState();

    const baObj = {
      fbUid: myinfo.fbUid,
      country: myinfo.country,
      gender: myinfo.gender,
      state: myinfo.primeMatch,
    };
    ba('primemenu', undefined, baObj);

    setPrimeMatch(isOn);
  }

  return (
    <PageLayout>
      <AppBar title={brsc.setting_privacy} />
      <Body className="py-[16px]">
        <SettingList text={brsc.hide_city} icon={<Switch isOn={hideCity} />} onClick={() => onChangeHideCity(!hideCity)} />
        <SettingList text={brsc.screen_lock} icon={<Switch isOn={screenLock} />} onClick={() => onChangeScreenLock(!screenLock)} />
        {primeAvailable && <SettingList text="Prime Match" icon={<Switch isOn={primeMatch} />} onClick={() => onChangePrimeMatch(!primeMatch)} />}
      </Body>
    </PageLayout>
  );
};

export default PagePrivacySetting;
